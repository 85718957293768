import MainLayout from "../layouts/MainLayout";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

import MiPerfilImage from "../assets/images/help/mi_perfil.png";
import MiPerfilCuentaImage from "../assets/images/help/mi_perfil_cuenta.png";
import MiPerfilClaveLecturaImage from "../assets/images/help/mi_perfil_clave_de_lectura.png";
import VerCatalogoImage from "../assets/images/help/ver_catalogo.png";
import CatalogoMiniaturaImage from "../assets/images/help/catalogo_miniatura.png";
import CatalogoListaImage from "../assets/images/help/catalogo_lista.png";
import CatalogoOrdenarImage from "../assets/images/help/catalogo_ordenar.png";
import CrearClaveLecturaImage from "../assets/images/help/crear_clave_lectura.png";
import CrearClaveLectura2Image from "../assets/images/help/crear_clave_lectura_2.png";
import MiPerfilBidiAppImage from "../assets/images/help/mi_perfil_bidi_app.png";
import AdeImage from "../assets/images/help/ade.png";
import DispositivosImage from "../assets/images/help/dispositivos.png";
import EbookStreamingImage from "../assets/images/help/ebook_streaming.png";
import StreamingImage from "../assets/images/help/streaming.png";
import AdobeDevolver1Image from "../assets/images/help/adobe_devolver_1.png";
import AdobeDevolver2Image from "../assets/images/help/adobe_devolver_2.png";
import AdobeDevolver4Image from "../assets/images/help/adobe_devolver_4.png";
import HistorialImage from "../assets/images/help/historial.png";
import FiltrarImage from "../assets/images/help/filtrar.png";
import DevApp1Image from "../assets/images/help/dev_app.jpg";
import DevApp2Image from "../assets/images/help/dev_app2.jpg";
import DevApp3Image from "../assets/images/help/dev_app3.jpg";
import BuscadorImage from "../assets/images/help/buscador.png";
import Tags2Image from "../assets/images/help/tags_2.png";
import Tags9Image from "../assets/images/help/9.tags.PNG";
import Buscador7Image from "../assets/images/help/7.buscador.PNG";


function Help() {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const questions = [{
    title: "¿Cómo accedo a Mi Perfil?", content: <div>
      <p>
        Encontrarás el menú en la parte superior derecha, al presionar sobre el nombre, se desplegará el
        menú:
      </p>
      <br />
      <figure>
        <img src={MiPerfilImage} alt="" className="w-100" height="141" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Allí podrás optar por seleccionar Mi perfil, Mis libros, Mis reservas o Historial, e ingresar a la
        opción seleccionada:
      </p>
      <br />
      <figure>
        <img src={MiPerfilCuentaImage} alt="" className="w-100" height="233" title="Ayuda" width="500" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Dónde se localizan los libros?", content: <div>
      <p>
        En la home verás la opción "Ver Catálogo", o también, desplegando el menú principal--&gt; catálogo:
      </p>
      <br />
      <figure>
        <img src={VerCatalogoImage} alt="" className="w-100" height="131" title="Ayuda" width="500" />
      </figure>
      <br />
      <p>
        Al presionar el botón ingresarás al catálogo de libros, el cual podrás ver en vista mosaico o lista:
      </p>
      <br />
      <figure>
        <img src={CatalogoMiniaturaImage} alt="" className="w-100" height="178" title="Ayuda" width="280" />
      </figure>
      <br />
      <br />
      <figure>
        <img src={CatalogoListaImage} alt="" className="w-100" height="174" title="Ayuda" width="280" />
      </figure>
      <br />
      <p>
        Además, podrás ordenar el resultado por alguno de los siguientes campos:
      </p>
      <br />
      <figure>
        <img src={CatalogoOrdenarImage} alt="" className="w-100" height="174" title="Ayuda" width="280" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Cómo leo un libro en línea?", content: <div>
      <p>
        Si el libro que quieres tomar en préstamo presenta el ícono Leer, al presionar sobre el mismo, se
        abrirá el reader y podrás disfrutar de la lectura en línea.
      </p>
      <br />
      <figure>
        <img src={EbookStreamingImage} alt="" className="snip-img" title="Ayuda" width="300" />
      </figure>
      <br />
      <br />
      <figure>
        <img src={StreamingImage} alt="" className="w-100" height="131" title="Ayuda" width="500" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Cómo busco un libro?", content: <div>
      <p>
        El buscador se encuentra visible en todo el sitio de la Biblioteca Digital. Para realizar una
        búsqueda, debes posicionarte en el recuadro e introducir el texto a buscar (título, autor,
        editorial, categoría), presiona enter, o click en la lupa.
      </p>
      <br />
      <figure>
        <img src={BuscadorImage} alt="" title="Ayuda" width="600" className="snip-img" />
      </figure>
      <br />
      <p>
        Podrás ver el resultado de tu búsqueda en vista Mosaico o lista, y también ordenar el resultado
        obtenido.
      </p>
      <p>
        Además, podrás refinar el resultado obtenido a través de los filtros:
      </p>
      <br />
      <figure>
        <img src={FiltrarImage} alt="" className="w-100" height="124" title="Ayuda" width="280" />
      </figure>
      <br />
      <p>
        Para quitar todos los filtros, debes presionar "Borrar todo"
      </p>
    </div>
  },
  {
    title: "¿Cómo usar los TAGs?", content: <div>
      <p>
        Los tags son etiquetas asociadas a los libros que te permitirán encontrar de manera ágil, lo que
        estás buscando. Los tags se visualizan en la Home, en la parte inferior:
      </p>
      <br />
      <figure>
        <img src={Tags2Image} alt="" className="w-100" height="130" title="Ayuda" width="280" />
      </figure>
      <br />
      <p>
        Al presionar sobre el tag de interés visualizarás el resultado asociado:
      </p>
      <br />
      <figure>
        <img src={Tags9Image} alt="" className="w-100" height="111" title="Ayuda" width="280" />
      </figure>
      <br />
    </div>
  },
  {
    title: "¿Cómo puedo refinar el resultado de búsqueda?", content: <div>
      <p>
        Luego de realizar una búsqueda, o presionando sobre un tag, en el resultado de búsqueda verás esta
        opción:
      </p>
      <br />
      <figure>
        <img src={Buscador7Image} alt="" className="w-100" height="130" title="Ayuda" width="280" />
      </figure>
      <br />
      <p>
        Al presionar sobre el filtro, se despliegan los campos por los que se pueden aplicar filtros, y de
        esa manera refinar el resultado obtenido:
      </p>
      <br />
      <figure>
        <img src={FiltrarImage} alt="" className="w-100" height="111" title="Ayuda" width="280" />
      </figure>
      <br />
    </div>
  },
  {
    title: "En caso de tener un problema ¿Dónde puedo contactarme?", content: <div>
      <p>
        Por favor revisa las secciones Ayuda y Preguntas Frecuentes, donde puedes corroborar que no se
        encuentre allí la solución.
      </p>
      <p>
        Si el error persiste puedes contactarte desde Argentina al {institution.helpContactPhone1}
        {institution.helpContactPhone2 && <span>
          , {institution.helpContactPhone2} desde el exterior
        </span>}, o por email a {institution.helpContactEmail}
      </p>
      <p>
        Siempre indica tu email, institución a la que perteneces, dispositivo utilizado, y si el error te ha
        dado algún mensaje por favor colocarlo textualmente o una captura de pantalla.
      </p>
    </div>
  }];



  return (
    <MainLayout currentPageKey="Ayuda" currentPageName="Ayuda" >
      <div className="py-4">
        <div className="flex items-center">
          <div className="flex-grow">
            <h2 className="text-2xl font-bold">Ayuda</h2>
          </div>
        </div>
        <div className="py-4">
          {questions.map((q, index) =>
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              ><HelpIcon className="mr-2 text-[#258BD1]" /><Typography className="font-bold">{q.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {q.content}
              </AccordionDetails>
            </Accordion>
          )}

        </div>
      </div>
    </MainLayout>
  );
}

export default Help;