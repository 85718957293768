import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";
import { createDownload, getBook, getBookSuggestions, getDownloads, getWebContentData } from "../services/bidiService";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Chip, CircularProgress, IconButton } from "@mui/material";
import moment from 'moment';

import DownloadIcon from '@mui/icons-material/Download';
import TodayIcon from '@mui/icons-material/Today';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import CreateReservationModal from "../components/modals/CreateReservationModal";
import ReadingKeyModal from "../components/modals/ReadingKeyModal";
import Reader from "../components/Reader";
import PlayerModal from "../components/modals/PlayerModal"
import SimpleModal from "../components/modals/SimpleModal";

import FavouriteButton from "../components/FavouriteButton";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import Collection from '../components/Collection';
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";
import ReturnDownloadModal from "../components/modals/ReturnDownloadModal";
import { customText } from "../customization/customText";
import CreateLoan from "../components/modals/CreateLoan";
import Constributors from "../components/Constributors";

const Book = () => {
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  const [book, setBook] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState("");
  const [showCreateReservationModal, setShowCreateReservationModal] = useState(false);

  const [download, setDownload] = useState();

  const [showReader, setShowReader] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [showReadingKeyModal, setShowReadingKeyModal] = useState(false);
  const [showModalConfirmLoan, setShowModalConfirmLoan] = useState(false);

  const [webpSupported] = useState(isWebpSupported());
  const [bookSuggestions, setBookSuggestions] = useState();

  const [reader, setReader] = useState(Cookies.get('reader'));

  const [showCancelDownloadModal, setShowCancelDownloadModal] = useState(false);
  const [bio, setBio] = useState();
  const [description, setDescription] = useState();

  let navigate = useNavigate();

  const contributorText = customText('book.contributor_data');

  //TODO share logic with Download component
  const openWebContent = (delivery) => {
    setLoadingButton(true);
    getWebContentData(delivery.downloadUrl).then(function (response) {
      setLoadingButton(false);
      window.open(response.data.url, '_blank');
    });
  }

  const downloadContent = (delivery) => {
    setLoadingButton(true);
    if (!user.alreadyHaveReader && delivery.book.drm_type === 'Adobe') {
      setShowReadingKeyModal(true);
    }
    const domain = institution.downloadUrl || process.env.REACT_APP_DOWNLOAD_URL;
    setLoadingButton(false);

    if (isMobile) {
      window.open(domain + "app_deeplink/download?type=" + delivery.type + "&orderitem_id=" + delivery.id + "&link=" + delivery.downloadUrl);
    }
    else {
      window.open(delivery.downloadUrl, '_blank');
    }

  }


  const generateDownload = (callback) => {

    if (!user) {
      const inTwentyFiveMinutes = new Date(new Date().getTime() + 25 * 60 * 1000);
      Cookies.set('reader', true, { expires: inTwentyFiveMinutes });

      window.location = `/account/${institution.name}/login?r=${window.location.pathname}`;
      return;
    }
    setLoadingButton(true);
    createDownload(authContext.authData.customer_access_token, institution.name, user.id, book.catalog_item_id, book.loan_allowed).then(delivery => {
      setDownload(delivery);

      callback(delivery);
    }).catch(error => {
      switch (error.response.data.code) {
        case 209:
          setError("Alcanzaste la cantidad máxima de libros que puedes tomar prestados simultáneamente.");
          break;
        case 210:
          setError(`El libro ${book.title} ya se encuentra en la sección Mis Libros`);
          break;
        case 400:
          setError(`Su solicitud de este libro está en proceso. Podrá verlo en unos minutos en la sección Mis Libros`);
          break;

        default:
          setError("Momentánemente este libro no se encuentra disponible. Por favor, contáctese con el administrador de la Biblioteca.");
      }
      setLoading(false);
      setLoadingButton(false);
    });
  }

  const handleBack = () => {
    const noRedirectUrls = [
      '/tokenlogin'
    ];

    const url = new URL(document.referrer)

    if (noRedirectUrls.includes(url.pathname)) {
      navigate("/")
      return false;
    }

    document.referrer.includes(window.location.host) && navigate(-1) ? navigate(-1) : navigate("/")
  }

  useEffect(() => {
    const promises = [
      getBook(institution.name, id),
      getBookSuggestions(institution.name, id)
    ];
    if (user && authContext.authData.customer_access_token) {
      promises.push(getDownloads(authContext.authData.customer_access_token, institution.name, user.id));
    }

    Promise.all(promises).then(([book, bookSuggestions, responseDownloads]) => {
      let currentDownload;
      if (user && authContext.authData.customer_access_token) {
        currentDownload = responseDownloads.data.downloads.find(d => d.book.catalog_item_id === book.catalog_item_id);
        setDownload(currentDownload);
      }
      setBook(book);
      setBookSuggestions(bookSuggestions);
      setLoading(false);

      // Replace unwanted characters.
      if (book.contributors.length > 0 && book.contributors[0].bio) {
        // Split text by new lines and remove some unwanted characters.
        let separator = String.fromCharCode(92, 110);    //  "\","n"
        let text = book.contributors[0].bio.split(separator);

        text = text.map((paragraph) => {
          paragraph = paragraph.replaceAll(String.fromCharCode(92), "");
          paragraph = paragraph.trim();
          return paragraph;
        });
        setBio(text);
      }
      if (book.description) {
        // Split text by new lines and remove some unwanted characters.
        let separator = String.fromCharCode(92, 110);    //  "\","n"
        let text = book.description.split(separator);

        text = text.map((paragraph) => {
          paragraph = paragraph.replaceAll(String.fromCharCode(92), "");
          paragraph = paragraph.trim();
          return paragraph;
        });
        setDescription(text);
      }

      if (reader) {
        if (!currentDownload) {
          generateDownload((download) => {
            if (download.book.format === 'web') {
              openWebContent(download);
            } else {
              setShowReader(true);
            }
          }, download.book);
        } else {
          if (!!currentDownload.book.streaming_allowed) {
            setShowReader(true);
          } else if (download.book.format === 'web') {
            openWebContent(currentDownload);
          }
        }
        Cookies.remove('reader');
      }
    });


  }, []);

  const readStart = (download) => {
    setLoadingButton(true);
    if (download.book.format === 'web') {
      openWebContent(download);
    } else {
      setShowReader(true);
    }
  }

  return <MainLayout loading={loading} currentPageName={book?.title}>
    {book && <div className="flex flex-col gap-6 py-8">
      {showModalConfirmLoan && <CreateLoan isLoan={!!book.loan_allowed} LoanTime={book.loan_time} titleBook={book.title} handleSubmit={() => { setShowModalConfirmLoan(false); generateDownload((download) => { readStart(download) }) }} onClose={() => setShowModalConfirmLoan(false)} />}
      {showReadingKeyModal && <ReadingKeyModal onClose={() => { setShowReadingKeyModal(false); setLoadingButton(false) }} />}
      {showReader && <Reader download={download} onClose={() => { setShowReader(false); setLoadingButton(false) }} />}
      {showPlayer && <PlayerModal download={download} onClose={() => { setShowPlayer(false); setLoadingButton(false) }} />}
      {showCreateReservationModal && <CreateReservationModal book_title={book.title} catalog_item_id={book.catalog_item_id} onClose={() => { setShowCreateReservationModal(false); setLoadingButton(false) }} />}
      {error && <SimpleModal show={true} onClose={() => setError("")}>{error}</SimpleModal>}
      {showCancelDownloadModal && <ReturnDownloadModal download={download} onClose={() => { setShowCancelDownloadModal(false); setDownload(null) }} />}
      <div>
        <div className="flex flex-wrap gap-4 justify-center">

          <div className="flex-shrink-0 relative overflow-hidden">
            {!!book.loan_allowed && institution.showLoanPeriod && <div className="periodo_prestamo absolute  top-[1rem] left-[-3.75rem] px-2 py-1 z-10 -rotate-45 text-center text-sm font-semibold text-white w-56" style={{ backgroundColor: institution.primaryColor }}>Préstamo<br />por {book.loan_time} {book.loan_time == 1 ? "día" : "días"}</div>}
            <LazyLoadImage
              className="self-center w-[13.5rem] h-72 bg-no-repeat bg-center bg-cover bg-book-cover"
              src={(webpSupported) ? book.images.webp : book.images.jpeg}
              alt={`Tapa del libro ${book.title}`}
              onError={(event) => event.target.style.display = 'none'} />
          </div>

          <div className="flex flex-col gap-4 flex-grow">
            <div>
              <div className="flex items-center justify-between">
                <h2 className="font-bold text-2xl">{book.title}</h2>
                {user && book && <FavouriteButton disabledButton={loadingButton} book={book} />}
                <Button variant="outlined" disabled={loadingButton} startIcon={<ArrowBack />} onClick={handleBack}>volver</Button>
              </div>

              {<Constributors constributors={book.contributors} />}
            </div>

            <div className="flex flex-wrap gap-1">
              {!institution.has_custom_genres ?
                book.categories.map((c, index) =>
                  <a key={index} href={`/catalogo?categories=${c.id}`}>
                    <Chip key={index} label={c.name} clickable />
                  </a>
                ) :
                book.custom_genres.map((c, index) =>
                  <a key={index} href={`/catalogo?custom_genres=${c.id}`}>
                    <Chip key={index} label={c.description} clickable />
                  </a>
                )}
            </div>
            {/* Actions without active download */}
            {!download && <div className="mt-2 flex flex-wrap gap-4">

              {/*Buttons are disabled when you want to read*/}
              {loadingButton ? <IconButton className="flex justify-center py-8 " ><CircularProgress size='1.1rem' /></IconButton> :
                <>
                  {((!!book.streaming_allowed) || book.format === 'web') && book.available_copies > 0 &&
                    <Button variant="contained" startIcon={<MenuBookIcon />} onClick={() => setShowModalConfirmLoan(true)}>Leer</Button>}

                  {book.media_type === 'audio' && book.available_copies > 0 &&
                    <Button variant="contained" disabled={loadingButton} startIcon={<HeadphonesIcon />} onClick={() => generateDownload(() => { setShowPlayer(true) })}>Escuchar ahora</Button>}

                  { /* Se quita porque ya no hay ACS, para volver a incluir quitar el último false */}
                  {((!!institution.downloadsAllowed && !!book.download_available) && book.available_copies > 0) &&
                    book.format !== 'web' && book.media_type !== 'audio' && book.streaming_model !== 'mandatory' &&
                    !!institution.enableDownloadButton && false &&
                    //  !!institution.showDownloadApps &&
                    // 31-05-2023 showDownloadApps now hide button instead disable it.
                    // <Button variant="contained" disabled={loadingButton || !institution.showDownloadApps } 
                    <Button variant="contained" disabled={loadingButton}
                      startIcon={<DownloadIcon />} onClick={() => generateDownload((download) => { downloadContent(download) })}>Descargar</Button>}

                  {!!institution.loansAllowed && book.available_copies === 0 &&
                    <Button variant="outlined" startIcon={<TodayIcon />} onClick={() => setShowCreateReservationModal(true)}>Reservar</Button>}
                </>
              }

              {!!book.loan_allowed &&
                <div className="w-full">
                  <div className="absolute">
                    <strong>Período de préstamo:</strong> {book.loan_time} {book.loan_time == 1 ? "día" : "días"}
                    {book.catalog_title && <div>
                      <div className="text-white text-xs uppercase rounded p-1 bg-[#7447C9] float-left" >{book.catalog_title}</div>
                    </div>}
                  </div>
                </div>}



              {/*!!institution.salesAllowed && book.saleslink &&
                <Button variant="outlined" startIcon={<ShoppingCartIcon />} href={book.saleslink}>Comprar</Button>*/}
            </div>}

            {/* Actions with an active download */}
            {download && <div className="mt-2 flex flex-wrap gap-4">
              {loadingButton ? <IconButton className="flex justify-center py-8 " ><CircularProgress size='1.1rem' /></IconButton> :
                <>
                  {!!download.book.streaming_allowed && <Button variant="contained" disabled={loadingButton} startIcon={<MenuBookIcon />} onClick={() => { { setShowReader(true); setLoadingButton(true) } }}>Seguir leyendo</Button>}
                  {download.book.format === 'web' && <Button variant="contained" disabled={loadingButton} startIcon={<MenuBookIcon />} onClick={() => openWebContent(download)}>Seguir leyendo</Button>}
                  {download.book.media_type === 'audio' && <Button variant="contained" disabled={loadingButton} startIcon={<HeadphonesIcon />} onClick={() => { setShowPlayer(true); }}>Escuchar</Button>}

                  { /* Se quita porque ya no hay ACS, para volver a incluir quitar el último false */}
                  {download.book.format !== 'web' && download.book.media_type !== 'audio' && download.book.streaming_model !== 'mandatory' &&
                    !!institution.enableDownloadButton &&
                    !!download.book.download_available && false &&
                    // !!institution.showDownloadApps &&
                    <Button variant="contained" disabled={loadingButton} startIcon={<DownloadIcon />} onClick={() => { downloadContent(download); }}>Descargar</Button>}

                  <Button variant="outlined" disabled={loadingButton} onClick={() => setShowCancelDownloadModal(true)}>Devolver</Button>

                </>}

              {!!book.loan_allowed &&
                <div className="w-full">
                  <div className="absolute periodo_prestamo">
                    <strong>Período de préstamo:</strong> {book.loan_time} {book.loan_time == 1 ? "día" : "días"}
                  </div>
                </div>}

            </div>
            }
          </div>
        </div>
      </div>
      <div>
        <div><h3 className="font-semibold text-xl">Ficha técnica</h3></div>
        <div className="flex flex-wrap gap-x-8">
          <div>
            <div>
              <strong>Editorial:</strong> {book.publishers.map(p => p.name).join(', ')}{book.year && <>, <time>{moment(book.year).year()}</time></>}
            </div>
          </div>
          <div>
            <div>
              <strong>Idioma:</strong> {book.language.label}
            </div>
          </div>
          <div>
            <div>
              <strong>ISBN:</strong> {book.isbn}
            </div>
          </div>
          <div>
            <div>
              <strong>Formato:</strong> {book.format.toUpperCase()}
            </div>
          </div>
          {!!book.loan_allowed &&
            <div>
              <div>
                <strong>Copias disponibles:</strong> {book.available_copies}
              </div>
            </div>}

        </div>
      </div>
      {description && <div>
        <div><h3 className="font-semibold text-xl">Reseña</h3></div>
        <div>
          {description ? <div> {description.map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>;
          })} </div>
            : "No se encuentran disponibles los datos del autor"}
        </div>
      </div>}
      {book.contributors.length > 0 &&
        <div>
          <div><h3 className="font-semibold text-xl">{contributorText}</h3></div>
          {bio ? <div> {bio.map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>;
          })} </div>
            : "No se encuentran disponibles los datos del autor"}
        </div>
      }

    </div>
    }

    {/* Suggestions */}
    {
      bookSuggestions && bookSuggestions.items.length > 0 &&
      <div>
        <div> <h3 className="font-semibold text-xl">Sugerencias</h3>
          <Collection data={bookSuggestions}></Collection>
        </div>
      </div>
    }

  </MainLayout >
}

export default Book;
