import { Button } from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";
import { getWebContentData } from "../services/bidiService";
import Book from "./Book";
import ReturnDownloadModal from "./modals/ReturnDownloadModal";
import Reader from "./Reader"
import PlayerModal from "./modals/PlayerModal"
import { AuthContext } from "../contexts/AuthContext";
import ReadingKeyModal from "./modals/ReadingKeyModal";
import { isMobile } from "react-device-detect";

const Download = ({ download, onReturn }) => {

  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  const [showCancelDownloadModal, setShowCancelDownloadModal] = useState(false);
  const menu = [];
  const [showReader, setShowReader] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [showReadingKeyModal, setShowReadingKeyModal] = useState(false);

  if (download.returnable) {
    menu.push({ id: download.id, name: "Devolver", onClick: () => setShowCancelDownloadModal(true) });
  }

  const openWebContent = () => {
    getWebContentData(download.downloadUrl).then(function (response) {
      window.open(response.data.url, '_blank');
    });
  }

  const getHumanExpiration = (expirationDate) => {
    const now = moment(new Date());
    const end = moment(expirationDate);
    const duration = moment.duration(end.diff(now));

    if (duration.asDays() < 1) {
      return Math.round(duration.asHours()) + " horas";
    }
    return Math.round(duration.asDays()) == 1 ? Math.round(duration.asDays()) + " día" : Math.round(duration.asDays()) + " días";
  }

  return <>
    {showCancelDownloadModal && <ReturnDownloadModal download={download} onReturn={onReturn} onClose={() => setShowCancelDownloadModal(false)} />}
    {showReadingKeyModal && <ReadingKeyModal onClose={() => setShowReadingKeyModal(false)} />}
    {showReader && <Reader download={download} onClose={() => { setShowReader(false); }} />}
    {showPlayer && <PlayerModal download={download} onClose={() => { setShowPlayer(false); }} />}
    
    <Book
      book={download.book}
      menu={menu}
      cucarda={download.type !== 'download' && <>Vence en<br />{getHumanExpiration(download.expiration)}</>}
      cucardaColor="#a4a4a4"
      showCategories={false}
    >
      <div className="text-xs">
        {download.type === 'download' ? "Descarga" : "Préstamo"}: {moment(download.created).format("DD/MM/yyyy")}
      </div>
      <div>
        {!!download.book.streaming_allowed && <Button fullWidth size="small" variant="contained" onClick={() => { setShowReader(true); }}>Seguir leyendo</Button>}
        {download.book.format === 'web' && <Button fullWidth size="small" variant="contained" onClick={() => { openWebContent(); }}>Seguir leyendo</Button>}
        {download.book.media_type === 'audio' && <Button fullWidth size="small" variant="contained" onClick={() => { setShowPlayer(true); }}>Escuchar</Button>}
      </div>
    </Book>
  </>;
};

export default Download;