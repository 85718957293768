import MainLayout from "../layouts/MainLayout";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

function Faq() {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const questions = [{
    title: "¿Qué es Bidi?", content: <div>
      <p>
        Bidi es la Biblioteca Digital que se integra a todo tipo de institución. Bidi es un sitio web que le permite a los
        usuarios gestionar préstamos y reservas de libros asociados al catálogo de la Institución.
      </p>
    </div>
  },
  {
    title: "¿Qué puedo hacer Bidi?", content: <div>
      <p>
        Una vez logueado podrás navegar el catálogo, realizar búsquedas, solicitar préstamos, realizar reservas, cancelar reservas, ordenar el resultado de las búsquedas, refinar el resultado de las
        búsqueda, ver el detalle de los libros, administrar tu perfil.
      </p>
    </div>
  },
  {
    title: "¿Qué es un libro?", content: <div>
      <p>
        Un libro es un libro electrónico, también conocido como ecolibro o libro digital. En otras palabras, es la versión
        electrónica o digital de un libro. Esta creciente tecnología permite al lector disponer de una verdadera librería
        móvil y una gran variedad de títulos, sin moverse de su casa, lugar de trabajo, estudio o
        vacaciones.
      </p>
    </div>
  },
  {
    title: "¿Puedo leer los libros en línea?", content: <div>
      <p>
        Sí, siempre y cuando el libro soporte la modalidad de lectura en línea.
      </p>
    </div>
  },
  {
    title: "¿Qué es mi perfil?", content: <div>
      <p>
        Esta sección contiene las opciones que te permitirán configurar los datos en Mi Perfil y visualizar los libros en
        Mis libros, Mis Reservas y en el Historial. Desde "Mi perfil" podrás cambiar tu contraseña. En "Mis libros" visualizarás los libros activos. En "Mis reservas" verás los libros reservados, la opción para cancelarlos y la cantidad de personas
        que están delante tuyo. En el "Historial" verás todos los libros que has tomado prestados.
      </p>
    </div>
  },
  {
    title: "¿Cuántos libros puedo pedir en préstamo de manera simultánea?", content: <div>
      <p>
        La cantidad de libros está dispuesta por las reglas de la Institución. Si tienes dudas puedes contactarnos.
      </p>
    </div>
  },
  {
    title: "¿Cuántos libros puedo tener reservados de manera simultánea?", content: <div>
      <p>
        La cantidad de libros está dispuesta por las reglas de la Institución. Si tienes dudas puedes contactarnos.
      </p>
    </div>
  },
  {
    title: "¿Cómo me informan cuando un libro reservado ya está disponible?", content: <div>
      <p>
        Al ingresar por primera vez a Bidi, Aceptas la notificación a tu email de la disponibilidad del libro reservado.
        De esta manera te llegará a la dirección de email provista, la notificación del libro disponible. Revisa en la
        bandeja de spam, si no lo encuentras en la bandeja de entrada.
      </p>
    </div>
  },
  {
    title: "¿Puedo reservar un libro varias veces?", content: <div>
      <p>
        No, sólo puedes tener el libro en reserva una vez, y estará en la sección Mis Reservas.
      </p>
    </div>
  },
  {
    title: "¿Puedo cancelar una reserva?", content: <div>
      <p>
        Sí, debes ingresar a Mis Reservas, y presionar Cancelar Reserva.
      </p>
    </div>
  },
  {
    title: "¿Dónde visualizo mis libros solicitados en préstamos?", content: <div>
      <p>
        Se visualizarán en la sección Mis libros. Estarán ordenados desde el último tomado en préstamo hacia el primero.
      </p>
    </div>
  },
  {
    title: "¿Dónde visualizo mis libros reservados?", content: <div>
      <p>
        Se visualizarán en la sección Mis Reservas. Estarán ordenados desde el último reservado hacia el primero. Los
        libros que visualices en el listado, se podrán cancelar.
      </p>
    </div>
  },
  {
    title: "¿Qué se registra en Historial?", content: <div>
      <p>
        En esta sección se visualizarán todos los libros tomados en préstamos cuyo período de préstamo ha expirado o hayas
        devuelto anticipadamente.
      </p>
    </div>
  },
  {
    title: "¿Cómo eliminar los datos de mi cuenta?", content: <div>
      <p>
        Para comenzar este proceso deberás enviar un email a info@ticmas.com.
      </p>
    </div>
  },];

  questions.push({
    title: "En caso de tener un problema ¿Dónde puedo contactarme?", content: <div>
      <p>
        Por favor revisa las secciones Ayuda y Preguntas Frecuentes, donde puedes corroborar que no se encuentre allí la
        solución.
      </p>
      <p>
        Si el error persiste puedes contactarte desde Argentina al {institution.helpContactPhone1}
        {institution.helpContactPhone2 && <span>
          , {institution.helpContactPhone2} desde el exterior
        </span>}, o por email a {institution.helpContactEmail}
      </p>
      <p>
        Siempre indica tu email, institución a la que perteneces, dispositivo utilizado, y si el error te ha dado algún
        mensaje por favor colocarlo textualmente o una captura de pantalla.
      </p>
    </div>
  });

  return (
    <MainLayout currentPageName="Preguntas frecuentes">
      <div className="py-4">
        <div className="flex items-center">
          <div className="flex-grow">
            <h2 className="text-2xl font-bold">Ayuda</h2>
          </div>
        </div>
        <div className="py-4">
          {questions.map((q, index) =>
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              ><HelpIcon className="mr-2 text-[#258BD1]" /><Typography className="font-bold">{q.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {q.content}
              </AccordionDetails>
            </Accordion>
          )}

        </div>
      </div>
    </MainLayout>
  );
}

export default Faq;