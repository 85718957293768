import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { returnDownload } from "../../services/bidiService";
import Book from "../Book";
import SimpleModal from "./SimpleModal";

const ReturnDownloadModal = ({ download, onReturn, ...props }) => {
  const authContext = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [feedbackId, setFeedbackId] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    returnDownload(authContext.authData.customer_access_token, download.id, feedbackId).then(response => {
      setLoading(false);
      props.onClose();
      onReturn(download.id);
    }).catch(() => {
      setLoading(false);
    });
  };

  return <SimpleModal title="¿Deseas devolver el libro?" {...props} show={true}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <div className="flex flex-col justify-center gap-4 pt-4">
        <Book book={download.book} showCategories={false} />

        <FormControl fullWidth>
          <InputLabel id="feedback">Contanos por qué devolvés el libro</InputLabel>
          <Select
            value={feedbackId}
            labelId="feedback"
            id="feedback-select"
            label="Contanos por qué devolvés el libro"
            onChange={(event) => setFeedbackId(event.target.value)}
            required
          >
            <MenuItem value={1}>Terminé de leerlo</MenuItem>
            <MenuItem value={4}>Me equivoqué de eBook</MenuItem>
            <MenuItem value={2}>Este eBook no es de mi interés</MenuItem>
          </Select>
        </FormControl>

        <div className="flex gap-4">
          <Button fullWidth variant="outlined" onClick={() => props.onClose()}>Cancelar</Button>
          <Button fullWidth variant="contained" onClick={handleSubmit} disabled={!feedbackId}>Devolver</Button>
        </div>

      </div>}
  </SimpleModal>
};

export default ReturnDownloadModal;