import { IconButton } from '@mui/material';
import MenuMaterial from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const Menu = () => {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    setAnchorEl(null);
    if (item) {
      window.location.href = item.url;
    }
  };

  const items = [
    { url: '/perfil', title: 'Mi perfil', userLoggedIn: true },
    { url: '/mislibros', title: 'Mis libros', userLoggedIn: true },
    { url: '/misreservas', title: 'Mis reservas', userLoggedIn: true },
    { url: '/misfavoritos', title: 'Mis favoritos', userLoggedIn: true },
    { url: '/historial', title: 'Historial', userLoggedIn: true },

    { url: '/catalogo', title: 'Catálogo', userLoggedIn: false },
    { url: '/ayuda', title: 'Ayuda', userLoggedIn: false },
    { url: '/preguntasfrecuentes', title: 'Preguntas frecuentes', userLoggedIn: false },
    { url: '/terminosycondiciones', title: 'Términos y condiciones', userLoggedIn: false },

    { url: '/logout', title: 'Salir', userLoggedIn: true },
  ].filter((item) => !item.userLoggedIn || user).filter((item) => !item.salesAllowed || !!institution.salesAllowed);

  return (<>
    <IconButton aria-label="menu" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
      <MenuIcon />
    </IconButton>
    <MenuMaterial id="menu" anchorEl={anchorEl} open={open} onClose={() => handleClose()} MenuListProps={{ 'aria-labelledby': 'menu-button' }}>
      {items.map((i, index) => <MenuItem key={index} onClick={() => handleClose(i)}>{i.title}</MenuItem>)}
    </MenuMaterial>
  </>);
}

export default Menu;